<script setup>

import {computed, inject, ref} from "vue";
import {useI18n} from "vue-i18n";

const localeToUse = ref("de-AT")

import {useFrontEndStore} from "@/stores/frontEndStore";
import {useStoreMetaData} from "@/stores/storeMetaData";
import edaTools from "@/lib/es-bill-shared-js/misc/edaTools";
import timeTools from "@/lib/es-bill-shared-js/misc/timeTools";

const dayZero = ref(new Date(2022,1-1,1));
const today = new Date();

const datesInterested = [
  // new Date(2023,1-1,1),
  // new Date(2023,2-1,1),
  // new Date(2023,3-1,1),
  // new Date(2023,4-1,1),
  // new Date(2023,5-1,1),
  // new Date(2023,6-1,1),
  // new Date(2023,7-1,1),
  // new Date(2023,8-1,1),
  // new Date(2023,9-1,1),
  // new Date(2023,10-1,1),
  // new Date(2023,11-1,1),
  // new Date(2023,12-1,1),
  //
  // new Date(2024,1-1,1),
  // new Date(2024,2-1,1),
  // new Date(2024,3-1,1),
  // new Date(2024,4-1,1),
  // new Date(2024,5-1,1),
  // new Date(2024,6-1,1),
  // new Date(2024,7-1,1),
  // new Date(2024,8-1,1),
  // new Date(2024,9-1,1),
  // new Date(2024,10-1,1),
  // new Date(2024,11-1,1),
  // new Date(2024,12-1,1),
  //
  // new Date(2025,1-1,1),
  // new Date(2025,2-1,1),
  // new Date(2025,3-1,1),
  //
  // today,
];

let currentDate = new Date(2022,6-1,1);
while(currentDate < today) {
  datesInterested.push(new Date(currentDate))
  currentDate.setMonth(currentDate.getMonth()+1);
}
datesInterested.push(new Date(today))

const { t } = useI18n();
const $log = inject('log');

const frontEndStore = useFrontEndStore();
const metaDataStore = useStoreMetaData();

const communitiesNotDemoNorEP = computed(() => {
  return metaDataStore.allCommunities.filter(c =>
    edaTools.isEcnValidExternal(c.ecn) && !c.ecn.startsWith("EP")
  )
})

const membershipsNotDemoNorEP = computed(() => {
  return metaDataStore.allMeteringPointMemberships.filter(mpm =>
    edaTools.isEcnValidExternal(mpm.ecn) && !mpm.ecn.startsWith("EP"))
})

const communitiesReport = computed(() => {
  let cr = [];

  for(const c of  communitiesNotDemoNorEP.value) {
    // $log.warn(c);

    const mpms = metaDataStore.allMeteringPointMemberships.filter(mpm => mpm.ecid === c.ecid);
    // first MPM activated;
    let mpmsActive = filterMpmsForActiveDuringPeriod(mpms,dayZero,today)

    if(mpmsActive.length === 0)
      continue;

    mpmsActive.sort((a, b) => a.dateStart - b.dateStart);

    const mpmsActiveToday = filterMpmsForActiveDuringPeriod(mpms,today,today)

    cr.push({
      ...c,
      mpmsAll: mpms,
      mpmsActive: mpmsActive,
      dateFirstActive: mpmsActive.length?mpmsActive[0].dateStart:null,
      mpmsActiveToday: mpmsActiveToday,

    });
  }

  cr.sort((a, b) => a.dateFirstActive - b.dateFirstActive);

  return cr;
});



const reportsByTimeInterested  = computed(() => {
  let result = [];
  let resultLast = null;

  for(const dateSubject of datesInterested){

    const communityActiveOnDate = filterCommunitiesActiveDuringPeriod(dateSubject,dateSubject);
    const mpmsActiveOnDate = filterMpmsForActiveDuringPeriod(membershipsNotDemoNorEP.value,dateSubject,dateSubject)

    let clientCodesUnique = [];
    for(const mpm of mpmsActiveOnDate) {
      if(clientCodesUnique.indexOf(mpm.clientCode) === -1)
        clientCodesUnique.push(mpm.clientCode)
    }


    result.push({
      dateSubject,
      mpmsActiveOnDate: mpmsActiveOnDate.length,
      communityActiveOnDate: communityActiveOnDate.length,
      clientCodesUnique: clientCodesUnique.length,

      changeMpms:        resultLast?(mpmsActiveOnDate.length/resultLast.mpmsActiveOnDate-1)*100:0,
      changeCommunities: resultLast?(communityActiveOnDate.length/resultLast.communityActiveOnDate-1)*100:0,
      changeMembers:     resultLast?(clientCodesUnique.length/resultLast.clientCodesUnique-1)*100:0,

      ratioMpToEg: mpmsActiveOnDate.length/communityActiveOnDate.length,
      ratioMeToEg: clientCodesUnique.length/communityActiveOnDate.length,
      ratioMpToMe: mpmsActiveOnDate.length/clientCodesUnique.length,

    })
    resultLast = result[result.length-1];
    console.warn(resultLast)
  }

  return result;
});

function filterMpmsForActiveDuringPeriod(mpms, dateStart, dateEnd) {
  let mpmResult = [];

  for(const mpm of mpms) {

    // never reached active state
    if(mpm.membershipStatus === "CREATED")
      continue;
    if(mpm.membershipStatus === "STARTED")
      continue;
    if(mpm.membershipStatus === "DECLINED")
      continue;
    if(mpm.membershipStatus === "WAITING_FOR_USER")
      continue;
    if(mpm.membershipStatus === "ACCEPTED")
      continue;

    // ended before our time period
    if(mpm.dateEnd && mpm.dateEnd < dateStart)
      continue;
    if(mpm.dateStart > dateEnd)
      continue;

    mpmResult.push(mpm)
  }

  return mpmResult;
}

function filterCommunitiesActiveDuringPeriod(dateStart, dateEnd) {
  let result = [];

  for(const community of communitiesReport.value) {

    // community currently has only start
    if(community.dateFirstActive > dateEnd)
      continue;

    result.push(community)
  }

  return result;
}


</script>


<script>
export default {
  name: 'meteringPointsAnalysis',
}
</script>


<template>
  <v-container class="containerBase pa-0">

    <v-container class="containerStandard">

      <p class="textHeadline">{{ $t('statistics') }}</p>
      Stats.<br><br>

      Anzahl Gemeinschaften (ohne Demo): {{ communitiesNotDemoNorEP.length }}<br>

      <br><br>

      Auszug an Stichtagen:
      Aktiver EG = zumindest 1 ZP war bereits aktiv.<br>
      Aktives MG = Mitglied hat aktiven ZP.<br>
      Aktiver ZP = ZP nimmt aktiv an Energieverteilung teil.<br>
      <u>Verschleppung/Verschlechterung der Statistik durch verschieden lange Aktivierungszeiten der ZP durch VNBs.</u>
      <br><br>
<!--      <div v-for="statsOnDay of reportsByTimeInterested">-->
<!--        <b>{{ timeTools.formatDate(statsOnDay.dateSubject) }}</b>-->
<!--        {{ statsOnDay.communityActiveOnDate }} /-->
<!--        {{ statsOnDay.clientCodesUnique }} /-->
<!--        {{ statsOnDay.mpmsActiveOnDate }} //-->

<!--        {{ statsOnDay.changeCommunities.toFixed(2) }}% /-->
<!--        {{ statsOnDay.changeMembers.toFixed(2) }}% /-->
<!--        {{statsOnDay.changeMpms.toFixed(2)}}%-->

<!--        <br>-->

<!--      </div><br><br>-->

      <table>
        <tr>
          <td>Stichtag</td>

          <td>Gemeinschaften</td>
          <td>Mitglieder</td>
          <td>Zählpunkte</td>

          <td>Delta EG</td>
          <td>Delta MG</td>
          <td>Delta ZP</td>

          <td>ZP/EG</td>
          <td>MG/EG</td>
          <td>ZP/MG</td>
        </tr>

        <tr v-for="statsOnDay of reportsByTimeInterested">
          <td>{{ timeTools.formatDate(statsOnDay.dateSubject) }}</td>

          <td>{{ statsOnDay.communityActiveOnDate }}</td>
          <td>{{ statsOnDay.clientCodesUnique }}</td>
          <td>{{ statsOnDay.mpmsActiveOnDate }}</td>

          <td>{{ statsOnDay.changeCommunities.toFixed(1) }}%</td>
          <td>{{ statsOnDay.changeMembers.toFixed(1) }}%</td>
          <td>{{ statsOnDay.changeMpms.toFixed(1)}}%</td>

          <td>{{ statsOnDay.ratioMpToEg.toFixed(1) }}</td>
          <td>{{ statsOnDay.ratioMeToEg.toFixed(1) }}</td>
          <td>{{ statsOnDay.ratioMpToMe.toFixed(1) }}</td>

        </tr>
      </table>
      <br>


      <b>Details Gemeinschaften:</b><br>
      Mit (jemals) aktiven Zählpunkten, nach Aktivierungsdatum.<br><br>
      <div v-for="community of communitiesReport">
        <u>{{community.ecn}}: {{community.name}}<br></u>
        ECID: {{community.ecid}}<br>
        Erster ZP aktiv:  {{ timeTools.formatDate(community.dateFirstActive) }}<br>
        ZP heute aktiv: {{ community.mpmsActiveToday.length}}<br>
        <br>
      </div>




    </v-container>
  </v-container>
</template>
