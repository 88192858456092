<script setup>

import {computed, getCurrentInstance, onMounted, ref, watch, watchEffect} from "vue";
import {useRoute} from "vue-router";
import { useI18n } from "vue-i18n";
import {MenuItem}  from '@es-bill/es-bill-common-components';
import ViewStatistics from "@/views/ViewStatistics.vue";

const route = useRoute()

let routeIsViewHome = computed(() => route.name === "home");
let routeIsViewDsoContracts = computed(() => route.name === "dsoContracts")
let routeIsViewCreateDsoContracts = computed(() => route.name === "createDsoContracts")
let routeIsViewReplaceDsoCompanies = computed(() => route.name === "replaceDsoCompanies")
let routeIsViewDsoCompanies = computed(() => route.name === "dsoCompanies")
let routeIsViewDsoCompanyForm = computed(() => route.name === "dsoCompanyForm")
let routeIsViewEdaConnectionForm = computed(() => route.name === "edaConnectionForm")
let routeIsViewEdaConnections = computed(() => route.name === "edaConnections")
let routeIsViewMeteringPoints = computed(() => route.name === "meteringPoints")
let routeIsViewMeteringPointsAnalysis = computed(() => route.name === "meteringPointsAnalysis")
let routeIsViewStatistics = computed(() => route.name === "statistics")

const contractsMenuItems = [
  {text: 'dsoContracts', path: '/dsoContracts', active: routeIsViewDsoContracts},
  {text: 'createDsoContracts', path: '/createDsoContracts', active: routeIsViewCreateDsoContracts}
];

const companiesMenuItems = [
  {text: 'replaceDsoCompanies', path: '/replaceDsoCompanies', active: routeIsViewReplaceDsoCompanies},
  {text: 'dsoCompanies', path: '/dsoCompanies', active: routeIsViewDsoCompanies},
  {text: 'createDsoCompany', path: '/dsoCompanyForm', active: routeIsViewDsoCompanyForm}
];

const edaConnectionMenuItems = [
  {text: 'createEdaConnection', path: '/edaConnectionForm', active: routeIsViewEdaConnectionForm},
  {text: 'edaConnections', path: '/edaConnections', active: routeIsViewEdaConnections}
];

const meteringPointsMenuItems = [
  {text: 'meteringPoints', path: '/meteringPoints', active: routeIsViewMeteringPoints},
  {text: 'analysis', path: '/meteringPoints/analysis', active: routeIsViewMeteringPointsAnalysis},
  {text: 'statistics', path: '/statistics', active: routeIsViewStatistics},
];

const keycloak = getCurrentInstance().appContext.app.$keycloak;

const {locale} = useI18n(); // Use the useI18n composable
const currentLocale = ref(locale.value); // Reactive reference for current locale

// List of available locales
const locales = [
  {text: 'Deutsch', value: 'de'},
  {text: 'English', value: 'en'},
  {text: 'Italiano', value: 'it'}
];

// Watch for changes in currentLocale and update the i18n locale
watch(currentLocale, (newLocale) => {
  locale.value = newLocale;
});
</script>

<template>
  <v-app-bar height="80">
    <template v-slot:prepend>
      <div class="justify-start headerTitle menuBand">
        <router-link to="/"
          class="routerLinkNoDecoration">
          <img height="37px" src="@/assets/sostrom/logo.svg" alt="so-strom-Logo"/>
        </router-link>

        <!-- Contracts Menu -->
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" :class="{ 'v-btn--active': routeIsViewDsoContracts || routeIsViewCreateDsoContracts }">
              {{ $t('contracts') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in contractsMenuItems" :key="index">
              <router-link :to="item.path" class="routerLinkNoDecoration">
                <MenuItem :text="$t(item.text)" :active="item.active.value"/>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Companies Menu -->
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" :class="{ 'v-btn--active': routeIsViewDsoCompanies || routeIsViewReplaceDsoCompanies || routeIsViewDsoCompanyForm }">
              {{ $t('dsos') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in companiesMenuItems" :key="index">
              <router-link :to="item.path" class="routerLinkNoDecoration">
                <MenuItem :text="$t(item.text)" :active="item.active.value"/>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Eda Connection Menu -->
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" :class="{ 'v-btn--active': routeIsViewEdaConnectionForm || routeIsViewEdaConnections }">
              {{ $t('eda') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in edaConnectionMenuItems" :key="index">
              <router-link :to="item.path" class="routerLinkNoDecoration">
                <MenuItem :text="$t(item.text)" :active="item.active.value"/>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- MeteringPoints -->
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" :class="{ 'v-btn--active': routeIsViewMeteringPoints }">
              {{ $t('meteringPoints') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in meteringPointsMenuItems" :key="index">
              <router-link :to="item.path" class="routerLinkNoDecoration">
                <MenuItem :text="$t(item.text)" :active="item.active.value"/>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>


      </div>
    </template>

    <template v-slot:append>
      <div class="headerIcons">
<!--        <a href='https://portal.so-strom.at' target="_blank">-->
<!--          <v-btn variant="outlined" color="#878787">-->
<!--            {{ $t('energyData') }}-->
<!--          </v-btn>-->
<!--        </a>-->

<!--        <v-btn variant="outlined" color="#878787"-->
<!--               :href='encodeURI("mailto:support@so-strom.at?subject=Hilfe bei so-strom-Portal benötigt")'>-->
<!--          {{ $t('support') }}-->
<!--        </v-btn>-->

        <v-select
          v-model="currentLocale"
          :items="locales"
          item-title="text"
          item-value="value"
          variant="underlined"
          dense
        ></v-select>

        <v-avatar color="primary" class="avatarIcon">
            <span class="avatarIconText">
              {{
                keycloak.tokenParsed?.given_name?.substring(0, 1) +
                keycloak.tokenParsed?.family_name?.substring(0, 1)
              }}</span>
        </v-avatar>

        <v-btn size="normal" icon="mdi-logout" color="#878787"
               @click="keycloak.logout()"></v-btn>
      </div>

    </template>
  </v-app-bar>
</template>


<script>
export default {
  name: 'HeaderBar'
}
</script>


<style scoped>

.v-select {
  min-width: 100px;
}


.v-btn {
  text-transform: none;
}

.routerLinkNoDecoration {
  text-decoration: none;
}

.headerTitle {
}

.menuBand {
  padding: 12px 12px 12px 32px;

  display: flex;
  align-items: flex-start;
  gap: 40px;

  vertical-align: center;
}

.headerIcons {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 32px;
}

.avatarIcon {
  height: 40px;
  width: 40px;
}

.avatarIconText {
  color: white;
}


@media screen and (max-width: 800px) {
}

@media screen and (min-width: 1321px) {
}

@media screen and (max-width: 1320px) {
}

</style>
