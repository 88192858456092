import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue';
import HomeView from '../views/Home.vue'
import DsoContracts from "@/views/DsoContracts.vue";
import DsoContractForm from "@/views/DsoContractForm.vue";
import DsoReplace from "@/views/DsoCompaniesReplace.vue";
import DsoCompany from "@/views/DsoCompanies.vue";
import DsoCompanyForm from "@/views/DsoCompanyForm.vue";
import EdaConnectionForm from "@/views/EdaConnectionForm.vue";
import EdaConnections from "@/views/EdaConnections.vue";
import ViewMeteringpoints from "@/views/ViewMeteringpoints.vue";
import ViewMeteringPointsAnalysis from "@/views/ViewMeteringPointAnalysis.vue";
import ViewStatistics from "@/views/ViewStatistics.vue";

const routes = [
  { // keep the home route at first
    path: '/',
    name: 'home',
    meta: { titleKey: 'home'},
    component: HomeView,
  },
  {
    path: '/dsoContracts',
    name: 'dsoContracts',
    meta: { titleKey: 'dsoContracts'},
    component: DsoContracts,
  },
  {
    path: '/createDsoContracts/:id?',
    name: 'createDsoContracts',
    meta: { titleKey: 'createDsoContracts'},
    component: DsoContractForm,
  },
  {
    path: '/replaceDsoCompanies',
    name: 'replaceDsoCompanies',
    meta: { titleKey: 'replaceDsoCompanies'},
    component: DsoReplace
  },
  {
    path: '/dsoCompanies',
    name: 'dsoCompanies',
    meta: { titleKey: 'dsoCompanies'},
    component: DsoCompany
  },
  {
    path: '/dsoCompanyForm/:id?',
    name: 'dsoCompanyForm',
    meta: { titleKey: 'dsoCompanyForm'},
    component: DsoCompanyForm
  },
  {
    path: '/edaConnections',
    name: 'edaConnections',
    meta: { titleKey: 'edaConnections'},
    component: EdaConnections
  },
  {
    path: '/edaConnectionForm/:id?',
    name: 'edaConnectionForm',
    meta: { titleKey: 'edaConnectionForm'},
    component: EdaConnectionForm
  },
  {
    path: '/meteringPoints',
    name: 'meteringPoints',
    meta: { titleKey: 'meteringPoints'},
    component: ViewMeteringpoints
  },
  {
    path: '/meteringPoints/analysis',
    name: 'meteringPointsAnalysis',
    meta: { titleKey: 'analysis'},
    component: ViewMeteringPointsAnalysis
  },
  {
    path: '/statistics',
    name: 'ViewStatistics',
    meta: { titleKey: 'statistics'},
    component: ViewStatistics
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function hasQueryParam(route,param) {
  return Object.keys(route.query).includes(param);
}

export default router
